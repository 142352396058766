import { util } from '@helper';
import { AssessmentAPI } from '@services/api';
import { ReactElement, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  Container,
  Divider,
  Grid,
  Modal,
  Panel,
  Progress,
  Row,
  toaster,
} from 'rsuite';
import useIsTabActive from '../../../hooks/useIsTabActive';

import './styles.scss';

const { withRouter } = util;

function GeneralTest(): ReactElement {
  const navigate = useNavigate();
  const { token } = useParams();
  const [showTimeoutModal, setTimeoutModal] = useState<boolean>(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [answers, setAnswers] = useState<number[]>([]);
  const [applicationId, setApplicationId] = useState<any>(null);
  const [duedateMiliseconds, setDuedateMiliseconds] = useState(
    new Date().getTime(),
  );
  const [cooldown, setCooldown] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isTabActive = useIsTabActive();

  const init = async (): Promise<void> => {
    const data = await AssessmentAPI.generate(token ?? '')
      .then((val) => val.data ?? [])
      .catch((err) => {
        toaster.push(util.buildErrorMessage(err.message));
      });

    setQuestions(data ?? []);
    setAnswers(new Array(data.length).fill(0));
  };

  const initToken = async (): Promise<void> => {
    const data = await AssessmentAPI.get(token ?? '')
      .then((val) => val.data)
      .catch((err) => {
        toaster.push(util.buildErrorMessage(err.message));
      });

    const date = data.dueDate
      ? new Date(`${data.dueDate.split(' ').join('T')}Z`)
      : new Date();

    setApplicationId(data.jobApplicationId);
    setDuedateMiliseconds(date.getTime());
  };

  const renderTimeoutModal = (): JSX.Element => (
    <Modal
      overflow={false}
      backdrop
      open={showTimeoutModal}
      onClose={() => {
        setTimeoutModal(false);
        backToApplication();
      }}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-transfer-job.png`}`}
          className="bg-transfer-job"
          alt="bg-img"
        />
        <h3>Timeout!</h3>
        <p style={{ marginTop: '2em' }}>
          Your test-taking time has ended. We appreciate your efforts, but your
          application cannot be considered as the allotted time has ended.{' '}
          <br />
          <br />
          Feel free to explore and apply for future opportunities within the
          organization.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={backToApplication} appearance="primary">
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const backToApplication = (): void => {
    navigate(`/dashboard/candidate/my-application/${applicationId}`);
  };

  const callTimeoutAPI = (): void => {
    AssessmentAPI.timeout(token!)
      .then(() => null)
      .catch((err) => toaster.push(util.buildErrorMessage(err.message)));
  };

  const onSubmit = (): void => {
    if (cooldown <= 0) {
      toaster.push(util.buildErrorMessage('time is up!'));

      callTimeoutAPI();
      return;
    }

    const data: any[] = [];
    for (let i = 0; i < answers.length; i++) {
      const question = questions[i];

      data.push({
        questionId: question.id,
        type: question.type,
        answer: answers[i],
      });
    }

    AssessmentAPI.submit(token!, data)
      .then(() => navigate(`/dashboard/general-test/${token}/result`))
      .catch((err) => toaster.push(util.buildErrorMessage(err.message)));
  };

  // Reload the window when tab is inactive
  useEffect(() => {
    if (!isTabActive) {
      window.location.reload();
    }
  }, [isTabActive]);

  useEffect(() => {
    init();
    initToken();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      // const { data } = await axios.request({
      //   method: 'GET',
      //   url: 'http://worldtimeapi.org/api/timezone/Etc/GMT',
      // });
      // const currentTz = data.unixtime;
      // console.log(data);
      let diff = duedateMiliseconds - new Date().getTime();
      // let diff = duedateMiliseconds - currentTz;
      if (diff < 0) {
        diff = 0;

        if (!showTimeoutModal) {
          callTimeoutAPI();
        }

        setTimeoutModal(true);

        clearInterval(interval);
      }

      setCooldown(diff);
    }, 500);

    return () => clearInterval(interval);
  }, [duedateMiliseconds]);

  if (questions.length === 0) {
    return <div>Loading...</div>;
  }

  const question = questions[currentIndex];

  return (
    <>
      {renderTimeoutModal()}
      <Grid
        className="home-static-authorized-container"
        style={{ color: '#212121' }}
      >
        <Row>
          <Panel>
            <Container>
              <p className="generaltest-time" style={{ textAlign: 'right' }}>
                {formatTime(cooldown)}
              </p>
              <Progress.Line
                percent={((currentIndex + 1) / questions.length) * 100}
                status="active"
                style={{ padding: '0px' }}
                strokeWidth={16}
                showInfo={false}
              />
              <p>
                Question{' '}
                <b>
                  {currentIndex + 1} / {questions.length}
                </b>
              </p>
            </Container>
            <Divider style={{ marginTop: '24px', marginBottom: '24px' }} />
            <Container>
              <p
                className="generaltest-question"
                dangerouslySetInnerHTML={{ __html: question.question }}
              ></p>
            </Container>
            <br />
            {question.questions.map((val: any, index: number) => {
              const id = `answer-${index}`;

              return (
                <div key={id}>
                  <Panel
                    className="cursor-pointer"
                    onClick={() => {
                      answers[currentIndex] = val.id;
                      setAnswers([...answers]);
                    }}
                    bordered
                    style={{ backgroundColor: 'white' }}
                  >
                    <input
                      type="radio"
                      id={id}
                      name="answer"
                      style={{ scale: '1.2', marginTop: '4px' }}
                      value={val.id}
                      className="cursor-pointer"
                      checked={answers[currentIndex] === val.id}
                      onChange={() => {
                        answers[currentIndex] = val.id;
                        setAnswers([...answers]);
                      }}
                    />
                    <label
                      htmlFor={id}
                      style={{ marginLeft: '12px' }}
                      className="cursor-pointer"
                    >
                      {val.answer}
                    </label>
                  </Panel>
                  <br />
                </div>
              );
            })}
            <br />
            <Button
              className="cursor-pointer"
              appearance="primary"
              block
              onClick={() => {
                if (answers[currentIndex] === 0) {
                  toaster.push(
                    util.buildErrorMessage('please input answer first!'),
                  );
                  return;
                }

                if (currentIndex + 1 === questions.length) {
                  onSubmit();
                  return;
                }

                if (answers[currentIndex] !== 0) {
                  setCurrentIndex(currentIndex + 1);
                }
              }}
            >
              Submit Answer
            </Button>
          </Panel>
        </Row>
      </Grid>
    </>
  );
}

function formatTime(time: number): string {
  const seconds = Math.round(time / 1000);
  const minutes = Math.round(seconds / 60);

  return `${leadZero(minutes)}:${leadZero(seconds % 60)}`;
}

function leadZero(num: number): string {
  if (num < 10) {
    return `0${num}`;
  }

  return num.toString();
}

export default withRouter(withTranslation()(GeneralTest));
