import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  FaBookOpen,
  FaCheck,
  FaCheckDouble,
  FaChevronLeft,
  FaComments,
  FaFile,
  FaGraduationCap,
  FaHome,
  FaMapMarkerAlt,
  FaStar,
  FaTimes,
  FaUniversity,
  FaUpload,
  FaUser,
  FaWallet,
} from 'react-icons/fa';
import { util } from '@helper';
import {
  Affix,
  Button,
  Col,
  Grid,
  IconButton,
  Modal,
  Nav,
  Panel,
  Row,
  Stack,
  Uploader,
  toaster,
} from 'rsuite';
import { DocumentAPI, JobApplicationAPI, MiscAPI } from '@services/api';
import PlaceholderGrid from 'rsuite/esm/Placeholder/PlaceholderGrid';
import utils from '@helper/utils';
import AuthStorage from '@common/services/authStorage';
import moment from 'moment';
import { FileType } from 'rsuite/esm/Uploader';
import { FileDownload } from '@rsuite/icons';

const { withRouter } = util;

type State = {
  tabKey: string;
  type: string;
  loading: boolean;
  details: any;
  detailId: number;
  showModal: boolean;
  showShortlistModal: boolean;
  templateLink: string;
  files: FileType[];
};

class HiringPartnerApplicationDetailScreen extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      tabKey: 'cv',
      type: 'reject',
      details: null,
      detailId: 0,
      loading: true,
      showModal: false,
      showShortlistModal: false,
      templateLink: '',
      files: [],
    };
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    const { params } = this.props;
    const id = _.get(params, 'id');

    this.setState({
      detailId: id,
    });

    this.getApplicationDetail(id);
    this.getOfferLetterTemplate();
  }

  private uploadDocument = async (
    { files, detailId } = this.state,
  ): Promise<boolean> => {
    if (files.length === 0) {
      toaster.push(
        util.buildErrorMessage('You should upload at least 1 file!'),
      );
      return false;
    }

    try {
      const blob = {
        blobFile: _.get(files, '0.blobFile'),
        name: `offering_letter_file.pdf`,
      };
      if (Number(_.get(blob.blobFile, 'size')) > 2097152) {
        toaster.push(
          util.buildErrorMessage(
            'File size is bigger than maximum file size: 2MB!',
          ),
        );

        return false;
      }

      const base64 = await util.blobToBase64(blob.blobFile);
      await DocumentAPI.upload({
        content: base64,
        table: 'App\\Models\\JobApplication',
        tableId: detailId,
        filename: blob.name,
      });

      return true;
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
      return false;
    }
  };

  private getOfferLetterTemplate = async (): Promise<void> => {
    const year = moment().format('YYYY');
    const response = await MiscAPI.getTemplates(`OFFERINGLETTER_${year}`);
    const link = _.get(response, 'data.0.url');
    this.setState({
      templateLink: link || '',
    });
  };

  private getApplicationDetail = async (id: any): Promise<void> => {
    try {
      this.setState({ loading: true });
      const response = await JobApplicationAPI.details(id);
      const details = _.get(response, 'data');

      this.setState({
        details,
        loading: false,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private onApproveButtonClicked = async (
    e: any,
    id: number,
    status: string,
  ): Promise<void> => {
    try {
      if (status === 'TECHNICAL_TEST_SUBMITTED_BY_CANDIDATE') {
        await JobApplicationAPI.updateStatusTo(id, 'TECHNICAL_TEST_PASSED');
      } else if (status === 'TECHNICAL_TEST_PASSED') {
        await JobApplicationAPI.updateStatusTo(id, 'INTERVIEW_IN_PROGRESS');
      } else if (
        status === 'INTERVIEW_IN_PROGRESS' ||
        status === 'INTERVIEW_ACCEPTED'
      ) {
        const isSuccess = await this.uploadDocument();
        if (isSuccess) {
          await JobApplicationAPI.updateStatusTo(id, 'INTERVIEW_ACCEPTED');
        } else {
          return;
        }
      }

      toaster.push(
        util.buildMessage('Application has been succcessfully approved.'),
      );

      this.setState({
        showModal: false,
        showShortlistModal: false,
      });

      this.getApplicationDetail(id);
    } catch (err: any) {
      toaster.push(util.buildErrorMessage(err.message));
    }
  };

  private onRejectButtonClicked = async (
    e: any,
    id: number,
    status: string,
  ): Promise<void> => {
    try {
      if (status === 'TECHNICAL_TEST_SUBMITTED_BY_CANDIDATE') {
        await JobApplicationAPI.updateStatusTo(
          id,
          'REJECTED_BY_HIRING_PARTNER',
        );
      } else if (status === 'TECHNICAL_TEST_PASSED') {
        await JobApplicationAPI.updateStatusTo(id, 'SHORTLIST_REJECTED');
      } else if (status === 'INTERVIEW_IN_PROGRESS') {
        await JobApplicationAPI.updateStatusTo(
          id,
          'INTERVIEW_REJECTED_BY_HIRING_PARTNER',
        );
      }

      toaster.push(
        util.buildMessage('Application has been succcessfully rejected.'),
      );

      this.setState({
        showModal: false,
        showShortlistModal: false,
      });

      this.getApplicationDetail(id);
    } catch (err: any) {
      toaster.push(util.buildErrorMessage(err.message));
    }
  };

  private redirectTo = (url: string): void => {
    const { navigate } = this.props;
    navigate(url, { replace: true });
  };

  renderShortlistModal = (
    { detailId, type, details } = this.state,
  ): JSX.Element | null => (
    <Modal
      overflow={false}
      backdrop="static"
      open={_.get(this.state, 'showShortlistModal')}
      onClose={() => {
        this.setState({ showShortlistModal: false });
      }}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-interview-hp.png`}`}
          className="bg-interview-modal-hp"
          alt="bg-img"
        />
        <h3>
          {type === 'reject' &&
            'Are you sure you want to reject the applicant?'}

          {type !== 'reject' &&
            `Are you sure that you want to shortlist the candidate?`}
        </h3>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            this.setState({ showShortlistModal: false });
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            if (type === 'reject') {
              this.onRejectButtonClicked(
                e,
                detailId,
                _.get(details, 'lastStatus'),
              );
            } else {
              this.onApproveButtonClicked(
                e,
                detailId,
                _.get(details, 'lastStatus'),
              );
            }
          }}
          disabled={_.get(this.state, 'disabled')}
          appearance="primary"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderModal = (
    { detailId, type, details, templateLink } = this.state,
  ): JSX.Element | null => (
    <Modal
      overflow={false}
      backdrop="static"
      open={_.get(this.state, 'showModal')}
      onClose={() => {
        this.setState({ showModal: false, files: [] });
      }}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-interview-hp.png`}`}
          className="bg-interview-modal-hp"
          alt="bg-img"
        />
        <h3>
          {type === 'reject' &&
            'Are you sure you want to reject the applicant?'}

          {type !== 'reject' &&
            `Are you sure that you want to extend the final offer to ${_.get(
              details,
              'user.name',
            )} - ${_.get(details, 'jobPosting.title')}?`}
        </h3>
        <br />
        <p>
          {type === 'reject' &&
            `${_.get(details, 'user.name')} - ${_.get(
              details,
              'jobPosting.title',
            )}`}

          {type !== 'reject' && (
            <div className="upload-offer-section">
              <h5>Upload Offering Letter</h5>
              <p>
                Make sure the uploaded documents are correct before submit them.
                You can not upload the document again if there are any errors.
              </p>
              <p className="description">
                Offering Letter template example:{' '}
                <a href={templateLink} target="_blank" rel="noreferrer">
                  here
                </a>
                <br />
                *Optional (you can use your version)
              </p>

              <Uploader
                multiple={false}
                // eslint-disable-next-line max-len
                accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                draggable
                action="#"
                maxPreviewFileSize={2097152}
                autoUpload={false}
                onChange={(files) => this.setState({ files })}
              >
                <div
                  style={{
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '12px',
                  }}
                >
                  <span>
                    Drag & drop your files here or <a href="#">choose file</a>
                    <br />
                    <br />
                    <small>2 MB max file size (*.PDF, *.doc, *.docx)</small>
                  </span>
                </div>
              </Uploader>
            </div>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            this.setState({ showModal: false, files: [] });
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            if (type === 'reject') {
              this.onRejectButtonClicked(
                e,
                detailId,
                _.get(details, 'lastStatus'),
              );
            } else {
              this.onApproveButtonClicked(
                e,
                detailId,
                _.get(details, 'lastStatus'),
              );
            }
          }}
          disabled={_.get(this.state, 'disabled')}
          appearance="primary"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderSideTabs = ({ location } = this.props): JSX.Element => (
    <Col lg={5} md={5} sm={0} xs={0}>
      <Affix className="tg-affix-side-panel" top={window.innerHeight * 0.15}>
        <Stack spacing={6} direction="column">
          <IconButton
            onClick={() => this.redirectTo('/dashboard/hiring-partner/home')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/hiring-partner/home')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaHome />}
          >
            Home
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/hiring-partner/mycompany')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/hiring-partner/mycompany',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaUser />}
          >
            My Company
          </IconButton>
        </Stack>
      </Affix>
    </Col>
  );

  renderTab = (key: string): Array<JSX.Element> | JSX.Element | null => {
    const { details } = this.state;

    switch (key) {
      case 'cv':
        return <iframe title="pdf" src={_.get(details, 'user.cv.url')} />;
      default:
        return (
          <iframe title="pdf" src={_.get(details, 'user.additionalFile.url')} />
        );
    }
  };

  renderEnglishProf = (s: string): string => {
    let proficiency = '';
    if (s === 'NO_BASIC') {
      proficiency = "Can't Speak English";
    } else if (s === 'BASIC') {
      proficiency = 'Basic';
    } else if (s === 'INTERMEDIATE') {
      proficiency = 'Intermediate';
    } else {
      proficiency = 'Advanced';
    }

    return proficiency;
  };

  renderApplicantDetailsCard = (
    { details, loading } = this.state,
  ): JSX.Element | null => (
    <Panel className="applicant-details-panel" bordered>
      <Stack spacing={8} direction="column">
        <Stack direction="column" style={{ marginBottom: '8px' }}>
          <Stack.Item>
            <p className="applicant-name">{_.get(details, 'user.name')}</p>
          </Stack.Item>
          <Stack.Item>Applied on {_.get(details, 'createdAt')}</Stack.Item>
        </Stack>

        {(_.get(details, 'user.profile.linkedinUrl') ||
          _.get(details, 'user.profile.instagramUrl')) && (
          <Stack direction="row" style={{ marginBottom: '16px' }}>
            <Row>
              {_.get(details, 'user.profile.instagramUrl') && (
                <Col xs={4}>
                  <img
                    className="social-media-logo"
                    alt="Instagram"
                    src="/logo-ig.png"
                  />
                </Col>
              )}
              {_.get(details, 'user.profile.linkedinUrl') && (
                <Col xs={4}>
                  <img
                    className="social-media-logo"
                    alt="LinkedIn"
                    onClick={() => {
                      window.open(
                        _.get(details, 'user.profile.linkedinUrl'),
                        '_blank',
                      );
                    }}
                    src="/logo-linkedin.png"
                  />
                </Col>
              )}
            </Row>
          </Stack>
        )}

        <Stack
          spacing={8}
          direction="column"
          style={{ alignItems: 'flex-start' }}
        >
          <Row>
            <Col xs={3}>
              <FaWallet />
            </Col>
            <Col xs={21}>
              <p className="applicant-details-attribute-title">
                Expected Salary
              </p>
              {loading && <PlaceholderGrid rows={1} columns={1} active />}
              {!loading && (
                <p className="applicant-details-attribute-content">
                  {util.formatSalaryRange(
                    _.get(details, 'user.preference.salaryLowRange'),
                    _.get(details, 'user.preference.salaryHighRange'),
                  )}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <FaCheckDouble />
            </Col>
            <Col xs={21}>
              <p className="applicant-details-attribute-title">
                English Proficiency
              </p>
              {loading && <PlaceholderGrid rows={1} columns={1} active />}
              {!loading && (
                <p className="applicant-details-attribute-content">
                  {this.renderEnglishProf(
                    _.get(details, 'user.profile.englishProficiency'),
                  )}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <FaBookOpen />
            </Col>
            <Col xs={21}>
              <p className="applicant-details-attribute-title">General Test</p>
              {loading && <PlaceholderGrid rows={1} columns={1} active />}
              {!loading && (
                <p className="applicant-details-attribute-content">
                  {_.get(details, 'user.generalTestScore') || '-'}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <FaMapMarkerAlt />
            </Col>
            <Col xs={21}>
              <p className="applicant-details-attribute-title">Domicile</p>
              {loading && <PlaceholderGrid rows={1} columns={1} active />}
              {!loading && (
                <p className="applicant-details-attribute-content">
                  {_.get(details, 'user.preference.jobLocation', '-')}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <FaUniversity />
            </Col>
            <Col xs={21}>
              <p className="applicant-details-attribute-title">University</p>
              {loading && <PlaceholderGrid rows={1} columns={1} active />}
              {!loading && (
                <p className="applicant-details-attribute-content">
                  {_.get(details, 'user.profile.university') || '-'}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <FaGraduationCap />
            </Col>
            <Col xs={21}>
              <p className="applicant-details-attribute-title">Major</p>
              {loading && <PlaceholderGrid rows={1} columns={1} active />}
              {!loading && (
                <p className="applicant-details-attribute-content">
                  {_.get(details, 'user.profile.major') || '-'}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <FaStar />
            </Col>
            <Col xs={21}>
              <p className="applicant-details-attribute-title">Skills</p>
              {loading && <PlaceholderGrid rows={1} columns={1} active />}
              {!loading && (
                <p className="applicant-details-attribute-content">
                  {String(_.get(details, 'user.profile.softSkills')) || '-'}
                </p>
              )}
            </Col>
          </Row>
        </Stack>
      </Stack>
    </Panel>
  );

  renderActionItemsCard = ({ details } = this.state): JSX.Element | null => {
    const lStatus = _.get(details, 'lastStatus');
    const approver = _.get(details, 'approver.name');

    if (lStatus === 'INTERVIEW_ACCEPTED' || lStatus === 'OFFER_ACCEPTED') {
      return (
        <Panel className="applicant-details-panel" bordered>
          <Stack spacing={8}>
            <Stack.Item>
              <p>Accepted by {approver || '-'}</p>
            </Stack.Item>
            <Stack.Item>
              <Button block className="applicant-accept-btn">
                <FaCheck />
                Accepted
              </Button>
            </Stack.Item>
          </Stack>

          {lStatus === 'INTERVIEW_ACCEPTED' && (
            <>
              <br />
              <Stack spacing={8}>
                <Button
                  block
                  className="applicant-accept-btn"
                  style={{ background: '#004AAD' }}
                  onClick={() => {
                    this.setState({
                      showModal: true,
                      type: 'accept',
                    });
                  }}
                  disabled={
                    !_.includes(
                      [
                        'TECHNICAL_TEST_PASSED',
                        'INTERVIEW_IN_PROGRESS',
                        'INTERVIEW_ACCEPTED',
                      ],
                      lStatus,
                    )
                  }
                >
                  <FaUpload />
                  Re-Upload
                </Button>
              </Stack>
            </>
          )}
        </Panel>
      );
    }

    if (lStatus === 'INTERVIEW_REJECTED_BY_HIRING_PARTNER') {
      return (
        <Panel className="applicant-details-panel" bordered>
          <Stack spacing={8}>
            <Stack.Item>
              <p>Rejected by {approver || '-'}</p>
            </Stack.Item>
            <Stack.Item>
              <Button block className="applicant-reject-btn">
                <FaCheck />
                Rejected
              </Button>
            </Stack.Item>
          </Stack>
        </Panel>
      );
    }

    if (lStatus === 'TECHNICAL_TEST_PASSED') {
      return (
        <Panel className="applicant-details-panel" bordered>
          <Stack spacing={8}>
            <Stack.Item>
              <Button
                block
                className="applicant-accept-btn"
                onClick={() => {
                  this.setState({
                    showShortlistModal: true,
                    type: 'accept',
                  });
                }}
              >
                <FaCheck />
                Shortlist
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button
                block
                className="applicant-reject-btn"
                onClick={() => {
                  this.setState({
                    showShortlistModal: true,
                    type: 'reject',
                  });
                }}
              >
                <FaTimes />
                Reject
              </Button>
            </Stack.Item>
          </Stack>
        </Panel>
      );
    }

    return (
      <Panel className="applicant-details-panel" bordered>
        <Stack spacing={8}>
          <Stack.Item
            style={{
              display: lStatus !== 'INTERVIEW_IN_PROGRESS' ? 'none' : 'block',
            }}
          >
            <Button
              onClick={async () => {
                const ph = _.get(details, 'user.phone');

                if (ph) {
                  const applicantName = _.get(details, 'user.name');
                  const user = AuthStorage.getInstance().getUser();
                  const hpName = _.get(user, 'name');
                  const companyName = _.get(user, 'company.name');
                  const roleName = _.get(details, 'jobPosting.title');
                  const id = _.get(details, 'id');
                  const status = _.get(details, 'lastStatus');
                  const message =
                    `Hi ${applicantName}, I am ${hpName} from ${companyName}.` +
                    ` I saw your profile from Talent Growth and` +
                    ` I am interested to proceed you to next interview stage` +
                    ` for ${roleName}.` +
                    ` Will you be available for the interview on` +
                    ` [DD/MM/YY] at [time] ?`;
                  window.open(
                    // eslint-disable-next-line max-len
                    `https://api.whatsapp.com/send?phone=${String(
                      _.get(details, 'user.phone'),
                    ).substring(1)}&text=${encodeURI(message)}`,
                  );

                  if (status !== 'INTERVIEW_IN_PROGRESS') {
                    await JobApplicationAPI.updateStatusToInterviewInProgress(
                      id,
                    );
                  }
                } else {
                  toaster.push(
                    utils.buildErrorMessage('Candidate phone is not exists'),
                  );
                }
              }}
              block
              className="applicant-interview-btn"
            >
              <FaComments />
              Interview
            </Button>
          </Stack.Item>

          <Stack.Item>
            <Button
              block
              className="applicant-accept-btn"
              onClick={() => {
                this.setState({
                  showModal: true,
                  type: 'accept',
                });
              }}
              disabled={lStatus !== 'INTERVIEW_IN_PROGRESS'}
            >
              <FaCheck />
              Accept
            </Button>
          </Stack.Item>

          <Stack.Item>
            <Button
              block
              className="applicant-reject-btn"
              onClick={() => {
                this.setState({
                  showModal: true,
                  type: 'reject',
                });
              }}
              disabled={lStatus !== 'INTERVIEW_IN_PROGRESS'}
            >
              <FaTimes />
              Reject
            </Button>
          </Stack.Item>
        </Stack>
      </Panel>
    );
  };

  renderSignedOfferLetterCard = (
    { details } = this.state,
  ): JSX.Element | null => {
    const signedOffer = _.get(details, 'signedOfferLetter');
    if (signedOffer === null) {
      return null;
    }

    return (
      <>
        <Panel className="applicant-details-panel" bordered>
          <Stack spacing={8} direction="column">
            <Stack direction="column" style={{ marginBottom: '8px' }}>
              <Stack.Item>
                <p className="applicant-name">Signed Offering Letter</p>
              </Stack.Item>
              <Stack.Item>
                Accepted by candidate on {_.get(details, 'updatedAt')}
              </Stack.Item>
              <Stack.Item>
                <Row className="applicant-signed-offer-letter">
                  <Col xs={4}>
                    <FaFile className="file-icon" />
                  </Col>
                  <Col xs={16}>
                    {_.get(details, 'signedOfferLetter.filename')}
                  </Col>
                  <Col xs={4}>
                    <FileDownload
                      onClick={async (e: any): Promise<void> => {
                        e.stopPropagation();

                        const url = _.get(details, 'signedOfferLetter.url');
                        const file = await fetch(url);
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(await file.blob());
                        link.download = _.get(
                          details,
                          'signedOfferLetter.filename',
                        );
                        link.target = '_blank';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                      className="download-icon"
                    />
                  </Col>
                </Row>
              </Stack.Item>
            </Stack>
          </Stack>
        </Panel>
        <br />
      </>
    );
  };

  renderMainContent = (): JSX.Element | null => {
    const { tabKey, details } = this.state;

    return (
      <Col
        md={24}
        sm={24}
        xs={24}
        lg={19}
        className="hp-application-details-main-content"
      >
        <Row>
          <Col xs={24}>
            <a href="/dashboard/hiring-partner/home">
              <FaChevronLeft />
              <span>Back to Applicants List</span>
            </a>
          </Col>

          <br />
          <br />
          <br />

          <Col xs={24} md={16}>
            <Nav
              activeKey={tabKey}
              onSelect={(key: string) => this.setState({ tabKey: key })}
              style={{ marginBottom: 50 }}
            >
              <Nav.Item eventKey="cv">CV / Resume</Nav.Item>
              {_.get(details, 'user.additionalFile.url') && (
                <Nav.Item eventKey="portofolio">
                  Portofolio / Additional Documents
                </Nav.Item>
              )}
            </Nav>

            {this.renderTab(tabKey)}
          </Col>

          <Col xs={24} md={8}>
            {this.renderActionItemsCard()}
            <br />
            {this.renderSignedOfferLetterCard()}
            {this.renderApplicantDetailsCard()}
          </Col>
        </Row>
      </Col>
    );
  };

  render(): JSX.Element | null {
    return (
      <>
        {this.renderShortlistModal()}
        {this.renderModal()}

        <Grid className="home-static-authorized-container" fluid>
          <Row>
            {this.renderSideTabs()}
            {this.renderMainContent()}
          </Row>
        </Grid>
      </>
    );
  }
}

export default withRouter(
  withTranslation()(HiringPartnerApplicationDetailScreen),
);
