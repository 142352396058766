import Http from '@services/httpClient';

const prefix = '/api/v1';

export default {
  async get(token: string) {
    return Http.get({
      url: `${prefix}/assessments/${token}`,
    })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  async generate(token: string) {
    return Http.get({
      url: `${prefix}/assessments/generate/${token}`,
    })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  async takeTest(token: string) {
    return Http.get({
      url: `${prefix}/assessments/take-test/${token}`,
    })
      .then((val) => val.data)
      .catch((e) => {
        throw e;
      });
  },
  async submit(token: string, answers: any[]) {
    return Http.post({
      url: `${prefix}/assessments/answers/${token}`,
      data: { answers },
    })
      .then((val) => val.data)
      .catch((e) => {
        throw e;
      });
  },
  async timeout(token: string) {
    return Http.post({
      url: `${prefix}/assessments/timeout/${token}`,
    })
      .then((val) => val.data)
      .catch((e) => {
        throw e;
      });
  },
};
